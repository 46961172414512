<template>
  <form @submit.prevent="onSubmit">
    <div class="new-user-info">
      <b-row class="justify-content-md-center">
        <b-form-group
          v-for="socialNetwork in socialNetworks"
          class="col-md-6 col-sm-12"
          :label="socialNetwork.name"
          :key="socialNetwork.id"
          label-for="name"
        >
          <b-input-group class="mt-3">
            <template #prepend>
              <i class="mt-2 mx-2" :class="iconSocial[socialNetwork.name]"></i>
            </template>
            <b-form-input
              v-model="formSocialNetworks[socialNetwork.name]"
              type="text"
              :placeholder="socialNetwork.name"
            >
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-row>
      <section class="float-right">
        <b-button
          :disabled="loading || !Object.keys(formSocialNetworks).length"
          variant="success"
          type="submit"
        >
          <span v-if="loading">
            <b-spinner small type="grow"></b-spinner>
            {{ $t("app.loading") }}...
          </span>
          <span v-else>
            {{ $t("app.update") }}
          </span>
        </b-button>
      </section>
      <br />
    </div>
  </form>
</template>

<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FormSocialNetworks",
  async mounted() {
    core.index();
    await this.fetchSocialNetworks();
  },
  data() {
    return {
      loading: false,
      formSocialNetworks: {},
      iconSocial: {
        Facebook: "ri-facebook-fill",
        Twitter: "ri-twitter-fill",
        Linkedin: "ri-linkedin-fill",
        Instagram: "ri-instagram-fill",
        Youtube: "ri-youtube-fill",
        TikTok: "ri-time-fill",
      },
    };
  },
  methods: {
    ...mapActions({
      fetchSocialNetworks: "fetchSocialNetworks",
      updateSocialNetworksCollege: "updateSocialNetworksCollege",
    }),
    setSocialNetworks(social_networks) {
      social_networks.forEach((socialNetwork) => {
        this.formSocialNetworks[socialNetwork.name] = socialNetwork.pivot.value;
      });
    },
    getWrappedForm() {
      let socialNetworks = [];
      Object.keys(this.formSocialNetworks).forEach((key) => {
        socialNetworks.push({
          id: this.socialNetworks.find(
            (socialNetwork) => socialNetwork.name === key
          ).id,
          value: this.formSocialNetworks[key],
        });
      });
      return socialNetworks;
    },
    async onSubmit() {
      this.loading = true;
      const resp = await this.updateSocialNetworksCollege({
        social_networks: this.getWrappedForm(),
        college_id: this.$route.params.id,
      });
      if (resp.status === 200) {
        core.showSnackbar(
          "success",
          "Redes sociales actualizadas correctamente"
        );
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      socialNetworks: "socialNetworks",
      college: "college",
    }),
  },
  watch: {
    college: {
      immediate: true,
      handler(college) {
        if (college) {
          this.setSocialNetworks(college.socialNetworks);
        }
      },
    },
  },
};
</script>

<style></style>
