<template>
  <b-modal id="modal-onboarding" hide-footer>
    <template #modal-header>
      <h4>
        {{ $t("app.new-image") }}
      </h4>
    </template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row align-h="between">
            <b-col md="12">
              <div class="text-center mb-4" style="cursor: pointer">
                <b-img
                  class="mt-2"
                  id="pick-image-onboarding"
                  style="max-width: 300px"
                  :src="
                    preview
                      ? preview
                      : require('@/assets/images/logo/placeholder.png')
                  "
                ></b-img>

                <avatar-cropper
                  :upload-handler="selectedFile"
                  @changed="setFileName"
                  trigger="#pick-image-onboarding"
                  :labels="{
                    submit: $t('app.accept'),
                    cancel: $t('app.cancel'),
                  }"
                  :cropper-options="{
                    aspectRatio: 0,
                    autoCropArea: 1,
                    viewMode: 1,
                    movable: true,
                    zoomable: true,
                  }"
                />
              </div>
            </b-col>
            <b-col mb="12">
              <b-row align-v="center">
                <!-- TITLE -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('app.title')"
                  label-for="title"
                >
                  <ValidationProvider
                    :name="$t('app.title')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="data.title"
                      type="text"
                      :placeholder="$t('app.title')"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                    </b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <!-- Content -->
                <b-form-group
                  class="col-md-12"
                  :label="$t('blog.content')"
                  label-for="content"
                >
                  <ValidationProvider
                    :name="$t('blog.content')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-textarea
                      v-model="data.content"
                      :placeholder="$t('blog.content')"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                      rows="3"
                    ></b-form-textarea>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <b-button
            class="float-right"
            :disabled="collegeLoading"
            variant="success"
            type="submit"
          >
            <span v-if="collegeLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            class="float-right mr-1"
            @click="$bvModal.hide('modal-onboarding')"
            variant="outline-danger"
            type="reset"
          >
            {{ $t("app.cancel") }}
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import AvatarCropper from "vue-avatar-cropper";

export default {
  name: "modalFormOnboarding",
  components: {
    AvatarCropper,
  },
  created() {
    core.index();
  },
  data() {
    return {
      fileSelected: null,
      preview: "",
      data: {
        title: null,
        content: null,
        image: null,
      },
    };
  },
  methods: {
    ...mapActions({
      saveForm: "addImageCollegeOnboarding",
    }),
    async show() {
      this.reserForm();
      this.$bvModal.show("modal-onboarding");
    },
    setFileName(file) {
      this.fileSelected = file;
      this.data.image = file;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.preview = dataUrl;
      this.data.image = file;
    },
    async onSubmit() {
      const resp = await this.saveForm({
        ...this.data,
        college_id: this.$route.params.id,
      });
      this.$bvModal.hide("modal-onboarding");
      this.$emit("refresh");
      if (resp?.status === 200) {
        core.showSnackbar("success", this.$t("app.success-image"));
      }
      this.reserForm();
    },
    reserForm() {
      this.preview = null;
      this.fileSelected = null;
      this.data = {
        title: null,
        content: null,
        image: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      collegeLoading: "collegeLoading",
    }),
  },
};
</script>
