<template>
  <section>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">OnBoarding</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" @click="$refs.modalOnboarding.show()">{{
          $t("app.new")
        }}</b-button>
      </template>
      <template v-slot:body>
        <div class="table-responsive">
          <b-table
            class="table mb-0 table-borderless"
            :items="getCollegeOnboarding"
            :fields="columns"
            :busy="
              !Array.isArray(getCollegeOnboarding) ||
              getCollegeOnboarding.length === 0
                ? true
                : false || collegeLoading
            "
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <div v-if="collegeLoading">
                  <b-spinner type="grow" class="align-middle"></b-spinner>
                  <strong>{{ $t("app.loading") }}...</strong>
                </div>
                <span class="h5" v-else>{{ $t("app.not-found") }}</span>
              </div>
            </template>

            <template v-slot:cell(action)="data">
              <b-button
                @click="deleteItem(data.item.id)"
                v-b-tooltip.hover
                title="Eliminar"
                variant=" iq-bg-danger rounded"
                size="sm"
                ><i class="ri-delete-bin-line ml-1"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </template>
    </iq-card>
    <modal-form-onboarding @refresh="getData()" ref="modalOnboarding" />
  </section>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalFormOnboarding from "./modalForm.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "CollegeOnboarding",
  components: {
    modalFormOnboarding,
  },
  mounted() {
    core.index();
    this.getData();
  },
  methods: {
    ...mapActions({
      fetchCollegeOnboarding: "fetchCollegeOnboarding",
      deleteCollegeOnboarding: "deleteCollegeOnboarding",
    }),
    getData() {
      this.fetchCollegeOnboarding(this.$route.params.id);
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteCollegeOnboarding(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
  computed: {
    ...mapGetters({
      getCollegeOnboarding: "collegeOnboarding",
      collegeLoading: "collegeLoading",
    }),
    columns() {
      return [
        {
          label: this.$t("app.title"),
          key: "custom_properties.title",
          class: "text-center",
        },
        {
          label: this.$t("blog.content"),
          key: "custom_properties.content",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
