<template>
  <b-container fluid>
    <b-row v-if="college">
      <b-col md="12">
        <iq-card class="pb-3">
          <template #headerTitle>
            <section class="d-flex align-items-center">
              <div
                v-if="user && user.super_admin_role"
                class="wrapper-menu"
                @click="
                  $router.push({
                    name: 'admin.schools',
                  })
                "
              >
                <div class="main-circle">
                  <i class="ri-arrow-left-s-line"></i>
                </div>
              </div>
              <h5 class="ml-2">
                {{ college.name }}
              </h5>
            </section>
          </template>
        </iq-card>
      </b-col>
      <b-col md="12">
        <iq-card class="pb-5">
          <template #headerTitle>
            <h5 class="mb-3">
              {{ $t("colleges.edit-college") }}
            </h5>
          </template>
          <template #body>
            <FormCollege @save="updateCollege" :collegeData="collegeData" />
          </template>
        </iq-card>
      </b-col>
      <b-col md="12">
        <iq-card>
          <template #headerTitle>
            <h5 class="mb-3">
              {{ $t("colleges.social-networks") }}
            </h5>
          </template>
          <template #body>
            <form-social-networks></form-social-networks>
          </template>
        </iq-card>
      </b-col>
      <b-col md="12">
        <college-onboarding></college-onboarding>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import FormSocialNetworks from "../FormSocialNetworks.vue";
import FormCollege from "@/components/Forms/FormsColleges.vue";
import CollegeOnboarding from "../OnBoarding";
export default {
  components: { FormSocialNetworks, FormCollege, CollegeOnboarding },
  async mounted() {
    core.index();
    await this.fetchCollege(this.$route.params.id);
  },
  data() {
    return {
      collegeData: "",
    };
  },
  methods: {
    ...mapActions({
      storeColleges: "storeColleges",
      fetchCollege: "fetchCollege",
    }),
    async updateCollege(data) {
      const resp = await this.storeColleges(data);
      if (resp.status === 200) {
        core.showSnackbar("success", this.$t("app.success-edit"));
      }
    },
  },
  computed: {
    ...mapGetters(["college", "user"]),
  },
  watch: {
    college: {
      immediate: true,
      handler(college) {
        this.collegeData = college ? college : "";
      },
    },
  },
};
</script>

<style></style>
